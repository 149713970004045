@font-face {
    font-family: 'Aeonik';
    font-style: normal;
    font-weight: 500;
    src: url('./Aeonik-Medium.ttf'), url('./Aeonik-Medium.ttf') format('truetype');
}

@font-face {
    font-family: 'Aeonik';
    font-style: normal;
    font-weight: 800;
    src: url('./Aeonik-Black.ttf'), url('./Aeonik-Black.ttf') format('truetype');
}

@font-face {
    font-family: 'Sharp Grotesk';
    font-style: normal;
    font-weight: 800;
    src: local('Sharp Grotesk'), url('./SharpGrotesk-Bold20.otf'), url('./SharpGrotesk-Bold20.otf') format('opentype');
}
